import React, { useContext } from "react";
import {
  BoldLink,
  BoxContainer,
  FormContainer,
  Input,
  LineText,
  MutedLink,
  SubmitButton,
} from "./common";
import { Marginer } from "../marginer";
import { AccountContext } from './accountContext';
import { useNavigate } from "react-router-dom";

import { login } from "../../context/AuthContext";

export function LoginForm(props) {

  const { switchToSignup } = useContext(AccountContext);
  const navigate = useNavigate();

  return (
    <BoxContainer>
      <FormContainer onSubmit={async (e)=>{
        console.log(e.target.email.value)
        console.log(e.target.password.value)
        e.preventDefault()
        
        const res =  await login(e.target.email.value,e.target.password.value)
    
      console.log(res)
      console.log('-------------------')
      if(res){
        navigate("/")}
      else{
        alert("Please check your email and password")
      }
      }}>
        <Input type="email" placeholder="Email" name="email" />
        <Input type="password" placeholder="Password" name="password" />
        <Marginer direction="vertical" margin="1.6em" />
        <center><SubmitButton type="submit">Signin</SubmitButton> </center>
      </FormContainer>
      <Marginer direction="vertical" margin={10} />
      {/* <MutedLink href="#">Forget your password?</MutedLink> */}
   
      <Marginer direction="vertical" margin="5px" />
      {/* <LineText>
        Don't have an accoun?{" "}
        <BoldLink onClick={switchToSignup} href="#">
          Signup
        </BoldLink>
      </LineText> */}
    </BoxContainer>
  );
}